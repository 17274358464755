import React, { useEffect, useRef, useState } from 'react';
import { IconArrowRight } from '@tabler/icons-react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import Markdown from 'react-markdown';
import { ActionIcon, Box, Button, Container, Group, Skeleton, Stack, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { AssistantApi, Configuration } from '@/api';
import { db, Firebase } from '@/firebase';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Typing } from '../components/Typing/Typing';
import styles from './ProductAssistantChat.module.css';

class ToolCallError extends Error {
    readonly id: string;
    constructor(id: string, message: string) {
        super(message); // Call the constructor of the base class `Error`
        this.id = id;
        this.name = 'ToolCallError';
        Object.setPrototypeOf(this, ToolCallError.prototype);
    }
}

interface AssistantAction {
    runId: string;
    toolCalls: {
        id: string;
        type: string;
        function: {
            name: string;
            arguments: string;
        };
    }[];
}

type MessageCategory = 'user' | 'assistant' | 'code' | 'typing' | 'confirmation';

type MessageProps = {
    category: MessageCategory;
    text: string;
    actions?: Record<string, () => Promise<void>>;
};

const UserMessage = ({ text }: { text: string }) => {
    return <div className={styles.userMessage}>{text}</div>;
};

const AssistantMessage = ({ text }: { text: string }) => {
    return (
        <div className={styles.assistantMessage}>
            <Markdown>{text}</Markdown>
        </div>
    );
};

const CodeMessage = ({ text }: { text: string }) => {
    return (
        <div className={styles.codeMessage}>
            {text.split('\n').map((line, index) => (
                <div key={index}>
                    <span>{`${index + 1}. `}</span>
                    {line}
                </div>
            ))}
        </div>
    );
};

interface Order {
    itemCode: string;
    itemName: string;
    quantity: number;
}

function isOrder(value: unknown): value is Order {
    return (
        typeof value === 'object' &&
        value !== null &&
        'itemCode' in value &&
        'itemName' in value &&
        'quantity' in value &&
        typeof (value as Order).itemCode === 'string' &&
        typeof (value as Order).itemName === 'string' &&
        typeof (value as Order).quantity === 'number'
    );
}

const ConfirmationMessage = ({ text, actions }: { text: string; actions?: { onConfirm?: () => void; onCancel?: () => void } }) => {
    const [disabled, setDisabled] = useState(false);
    const [confirming, setConfirming] = useState(false);
    return (
        <div className={styles.assistantMessage} style={{ width: '100%' }}>
            <Markdown>{text}</Markdown>
            <Stack style={{ flexDirection: 'row' }} gap="sm">
                {actions?.onConfirm && (
                    <Button
                        fullWidth
                        variant="filled"
                        onClick={async () => {
                            if (actions?.onConfirm) {
                                setConfirming(true);
                                await actions.onConfirm();
                                setConfirming(false);
                                setDisabled(true);
                            }
                        }}
                        loading={confirming}
                        disabled={disabled}
                    >
                        Confirm
                    </Button>
                )}
                {actions?.onCancel && (
                    <Button
                        fullWidth
                        variant="outline"
                        onClick={async () => {
                            if (actions?.onCancel) {
                                await actions.onCancel();
                                setDisabled(true);
                            }
                        }}
                        disabled={disabled}
                    >
                        Cancel
                    </Button>
                )}
            </Stack>
        </div>
    );
};

const Message = ({ category, text, actions }: MessageProps) => {
    switch (category) {
        case 'user':
            return <UserMessage text={text} />;
        case 'assistant':
            return <AssistantMessage text={text} />;
        case 'code':
            return <CodeMessage text={text} />;
        case 'typing':
            return <Typing />;
        case 'confirmation':
            return <ConfirmationMessage text={text} actions={actions} />;
        default:
            return null;
    }
};

const ProductAssistantChat = () => {
    // provider
    const { currentUser } = useAuth();

    // ui
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<MessageProps[]>([]);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [threadId, setThreadId] = useState('');
    const theme = useMantineTheme();
    const isMobile = useMediaQuery('(max-width: 50em)');

    // automatically scroll to bottom of chat
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // create a new threadID when chat component created
    useEffect(() => {
        const createThread = async () => {
            try {
                const token = await currentUser?.getIdToken();
                if (!token) {
                    return;
                }
                const api = new AssistantApi(new Configuration({ accessToken: token }));
                const res = await api.threadsPost({ topic: 'product' });
                if (res.data.threadId) {
                    setThreadId(res.data.threadId);
                    setMessages([]);
                }
            } catch (error) {
                capture(error);
            }
        };
        createThread();
    }, [currentUser]);

    const createOrder = async (order: Order) => {
        if (!currentUser) {
            throw new Error('User not authenticated');
        }

        const idTokenResult = await currentUser?.getIdTokenResult();
        await addDoc(collection(db, Firebase.Firestore.Collection.Orders), {
            ...order,
            oid: idTokenResult?.claims.org,
            requester: currentUser?.email,
            creation: serverTimestamp(),
        });
    };

    const sendMessage = async (threadId: string, text: string) => {
        try {
            const token = await currentUser?.getIdToken();
            if (!token) {
                return;
            }
            const api = new AssistantApi(new Configuration({ accessToken: token }));
            const res = await api.threadsThreadIdMessagesPost(threadId, { content: text });

            if (res.data?.action?.toolCalls && res.data.action.toolCalls.length > 0) {
                await handleRequiresAction(res.data.action);
            }

            if (res.data?.reply) {
                appendMessage('assistant', res.data?.reply);
            }

            if (!res.data?.reply && (!res.data?.action?.toolCalls || res.data?.action?.toolCalls.length === 0)) {
                appendMessage('assistant', 'Unable to process your inquiry. Please contact support for further assistance.');
            }
        } catch (error) {
            capture(error);
        } finally {
            handleRunCompleted();
        }
    };

    const handleRequiresAction = async (action: AssistantAction) => {
        const promises = action.toolCalls.map((call) => {
            switch (call.function.name) {
                case 'create_order': {
                    return new Promise<{ id: string }>((resolve, reject) => {
                        const args = JSON.parse(call.function.arguments);
                        if (!isOrder(args)) {
                            reject(new ToolCallError(call.id, 'invalid_arguments'));
                            return;
                        }

                        appendMessage('confirmation', `Please confirm your order:\n - **Item Code**: ${args.itemCode}\n - **Item Name**: ${args.itemName}\n - **Quantity**: ${args.quantity}`, {
                            onConfirm: async () => {
                                await createOrder(args).catch((error) => {
                                    notifications.show({ title: 'Error', message: error.message, color: 'red' });
                                    reject(new ToolCallError(call.id, 'internal_system_error'));
                                });
                                resolve({ id: call.id });
                            },
                            onCancel: async () => {
                                reject(new ToolCallError(call.id, 'user_cancelled'));
                            },
                        });
                    });
                }
                default:
                    notifications.show({ title: 'Unknown action', message: call.function.name, color: 'yellow' });
                    return Promise.reject(new ToolCallError(call.id, 'unknown_action'));
            }
        });

        // submit results of tool calls
        const results = await Promise.allSettled(promises);
        const toolCallOutputs: { output: string; tool_call_id: string }[] = results.map((result) => {
            if (result.status === 'fulfilled') {
                return { output: JSON.stringify({ success: true }), tool_call_id: result.value.id };
            }

            return { output: JSON.stringify({ success: false, reason: (result.reason as ToolCallError).message }), tool_call_id: (result.reason as ToolCallError).id };
        });

        appendMessage('typing', '');
        const api = new AssistantApi(new Configuration({ accessToken: await currentUser?.getIdToken() }));
        const respond = await api.threadsThreadIdActionsPost(threadId, { runId: action.runId, toolOutputs: toolCallOutputs });
        if (respond.data.reply) {
            appendMessage('assistant', respond.data.reply);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!userInput.trim()) {
            return;
        }

        setMessages((prevMessages) => [...prevMessages, { category: 'user', text: userInput }, { category: 'typing', text: '' }]);
        sendMessage(threadId, userInput);
        setUserInput('');
        setInputDisabled(true);
        scrollToBottom();
    };

    // handleRunCompleted - re-enable the input form
    const handleRunCompleted = () => {
        setInputDisabled(false);
        setMessages((prevMessages) => {
            if (prevMessages[prevMessages.length - 1]?.category === 'typing') {
                return [...prevMessages.slice(0, -1)];
            }
            return prevMessages;
        });
    };

    /*
    =======================
    === Utility Helpers ===
    =======================
    */

    const appendMessage = (category: MessageCategory, text: string, actions?: Record<string, () => Promise<void>>) => {
        setMessages((prevMessages) => {
            if (prevMessages[prevMessages.length - 1]?.category === 'typing') {
                return [...prevMessages.slice(0, -1), { category, text, actions }];
            }

            return [...prevMessages, { category, text, actions }];
        });
    };

    return (
        <>
            <Group className={styles.threadContainer} gap="xs">
                <Text size="sm" fw={700}>
                    THREAD
                </Text>
                {threadId ? (
                    <Text size="sm" c="gray">
                        {threadId}
                    </Text>
                ) : (
                    <Skeleton width={240} height={16} />
                )}
            </Group>
            <Box style={{ flex: 1, position: 'relative' }} mb={isMobile ? 'md' : 'xl'}>
                <Container size="sm" className={styles.chatContainer}>
                    <div className={styles.messages}>
                        {messages.map((msg, index) => (
                            <Message key={index} category={msg.category} text={msg.text} actions={msg.actions} />
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSubmit} className={styles.inputForm}>
                        <TextInput
                            value={userInput}
                            radius="xl"
                            size="lg"
                            placeholder="Enter your question"
                            rightSectionWidth={48}
                            rightSection={
                                <ActionIcon size={38} radius="xl" color={theme.primaryColor} variant="filled" disabled={inputDisabled || !userInput.trim() || !threadId} type="submit">
                                    <IconArrowRight size={22} stroke={2} />
                                </ActionIcon>
                            }
                            flex="auto"
                            onChange={(e) => setUserInput(e.currentTarget.value)}
                        />
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default ProductAssistantChat;
