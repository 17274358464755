import { useCallback, useEffect, useRef, useState } from 'react';
import { collection, endAt, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, where } from 'firebase/firestore';
import { DataTable } from 'mantine-datatable';
import { PageContainer } from '@/components/PageContainer/PageContainer';
import { db, Firebase } from '@/firebase';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Record } from '@/types/order';
import classes from './ProductOrders.module.css';

const PAGE_SIZE = 50;

const ProductOrdersTable = () => {
    const { currentUser } = useAuth();

    // Generic state
    const [org, setOrg] = useState<string | null>(null);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | undefined>(undefined);

    // DataTable state
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<Record[] | undefined>(undefined);
    const scrollViewportRef = useRef<HTMLDivElement>(null);

    const loadMoreRecords = useCallback(
        (lastVisible?: QueryDocumentSnapshot) => {
            if (!org) {
                return;
            }

            setLoading(true);

            const q = query(
                collection(db, Firebase.Firestore.Collection.Orders),
                orderBy('creation', 'desc'),
                where('oid', '==', org),
                ...(lastVisible ? [startAfter(lastVisible)] : []),
                limit(PAGE_SIZE)
            );

            getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords((r) => (r ? r.concat(result) : result));
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [org]
    );

    const refresh = useCallback(
        async (lastVisible?: QueryDocumentSnapshot) => {
            if (!org) {
                return;
            }

            setLoading(true);

            const q = query(collection(db, Firebase.Firestore.Collection.Orders), orderBy('creation', 'desc'), where('oid', '==', org), lastVisible ? endAt(lastVisible) : limit(PAGE_SIZE));

            await getDocs(q)
                .then((snapshot) => {
                    const result = snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    });
                    setRecords(result);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                })
                .catch((error) => capture(error))
                .finally(() => {
                    setLoading(false);
                });
        },
        [org]
    );

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then((idTokenResult) => {
                if (idTokenResult.claims.org && typeof idTokenResult.claims.org === 'string') {
                    setOrg(idTokenResult.claims.org);
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        setRecords(undefined);
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return (
        <DataTable
            withTableBorder={false}
            striped
            classNames={{
                header: classes.header,
            }}
            highlightOnHover
            columns={[
                {
                    accessor: 'itemCode',
                },
                {
                    accessor: 'itemName',
                },
                {
                    accessor: 'quantity',
                },
                {
                    accessor: 'requester',
                },
                {
                    accessor: 'creation',
                    render: ({ creation }) => `${creation?.toDate().toLocaleString()}`,
                    noWrap: true,
                },
            ]}
            records={records}
            fetching={loading}
            selectionTrigger="cell"
            onScrollToBottom={() => {
                if (records && lastVisible && org) {
                    loadMoreRecords(lastVisible);
                }
            }}
            scrollViewportRef={scrollViewportRef}
        />
    );
};

export function ProductOrders() {
    return (
        <PageContainer breadcrumbs={[{ title: 'Product' }, { title: 'Orders' }]}>
            <ProductOrdersTable />
        </PageContainer>
    );
}

export default ProductOrders;
