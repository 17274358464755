import { useEffect, useState } from 'react';
import { IconInvoice, IconMonkeybar, IconShoppingCart, IconSitemap, IconTerminal } from '@tabler/icons-react';
import { Outlet } from 'react-router-dom';
import { AppShell, Box, Burger, Center, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ColorSchemeControl } from '@mantinex/mantine-header';
import { NavbarLinkProps } from '@/components/NavbarLink/NavbarLink';
import { LinksGroupProps } from '@/components/NavbarLinksGroup/NavbarLinksGroup';
import { NavbarNested } from '@/components/NavbarNested/NavbarNested';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Role } from '@/types/role';

const defaultExpenseItem = {
    label: 'Expense',
    icon: IconInvoice,
    initiallyOpened: true,
    links: [
        { label: 'Upload', link: '/expense/upload' },
        { label: 'Claim', link: '/expense/claim' },
    ],
};

const defaultProductItem = {
    label: 'Product',
    icon: IconShoppingCart,
    links: [{ label: 'Assistant', link: '/product/assistant' }],
    initiallyOpened: true,
};

const defaultDevItem = {
    label: 'Playground',
    icon: IconMonkeybar,
    links: [
        { label: 'Invoice', link: '/playground/invoice' },
        { label: 'DataTable', link: '/playground/datatable' },
        { label: 'Analytics', link: '/playground/analytics' },
    ],
    initiallyOpened: false,
};

export function BasicAppShell() {
    const { currentUser } = useAuth();
    const [opened, { toggle }] = useDisclosure();

    const [navItems, setNavItems] = useState<(NavbarLinkProps | LinksGroupProps)[]>([]);

    useEffect(() => {
        if (currentUser) {
            currentUser.getIdTokenResult().then((idTokenResult) => {
                if (Array.isArray(idTokenResult.claims.roles)) {
                    const items = [];

                    // expense
                    if (idTokenResult.claims.roles.includes(Role.OrgAdmin)) {
                        items.push({ ...defaultExpenseItem, links: [...defaultExpenseItem.links, { label: 'Approval', link: '/expense/approval' }, { label: 'Export', link: '/expense/export' }] });
                    } else if (idTokenResult.claims.roles.includes('orgUser')) {
                        items.push({ ...defaultExpenseItem });
                    }

                    // product
                    if (idTokenResult.claims.roles.includes(Role.OrgAdmin)) {
                        items.push({ ...defaultProductItem, links: [...defaultProductItem.links, { label: 'Orders', link: '/product/orders' }] });
                    } else if (idTokenResult.claims.roles.includes(Role.OrgUser)) {
                        items.push({ ...defaultProductItem });
                    }

                    // org
                    if (idTokenResult.claims.roles.includes('orgAdmin')) {
                        items.push({ label: 'Organization', icon: IconSitemap, links: [{ label: 'Users', link: '/organization/users' }] });
                    }

                    // dev
                    if (idTokenResult.claims.roles.includes(Role.Dev)) {
                        items.push({ ...defaultDevItem });
                    }

                    setNavItems(items);
                }
            });
        }
    }, [currentUser]);

    return (
        <AppShell header={{ height: 60 }} navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }} padding="md">
            <AppShell.Header>
                <Group h="100%" px="md">
                    <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                    <Group justify="space-between" style={{ flex: 1 }}>
                        <Group gap={4}>
                            {/* <Image src={logo} alt="nk.ai" width={24} height={24} /> */}
                            <Box color="blue">
                                <Center c="blue.4">
                                    <IconTerminal size={24} />
                                </Center>
                            </Box>
                            <Text size="xl" fw={700}>
                                nk.ai
                            </Text>
                        </Group>

                        <Group>
                            <ColorSchemeControl />
                        </Group>
                    </Group>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <NavbarNested items={navItems} onLinkClick={() => toggle()} user={currentUser} />
            </AppShell.Navbar>
            <AppShell.Main display="flex">
                <Outlet />
            </AppShell.Main>
        </AppShell>
    );
}

export default function HomePage() {
    return <BasicAppShell />;
}
