import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Replace the values with your Firebase project's configuration
const firebaseConfig = {
    apiKey: 'AIzaSyCdub9nv7dQb-sjUbgRSTkUtYOZ1mJ6D4k',
    authDomain: 'nk-core-dev.firebaseapp.com',
    projectId: 'nk-core-dev',
    storageBucket: 'nk-core-dev.firebasestorage.app',
    messagingSenderId: '1072511093604',
    appId: '1:1072511093604:web:8b9d45331c2350b85c4871',
    measurementId: 'G-0D9WQZDBMR',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app, 'nk-core-dev');
// Initialize Firebase Storage
export const storage = getStorage();

export const Firebase = {
    Firestore: {
        Collection: {
            Claims: 'claims',
            Credits: 'credits',
            Orders: 'orders',
        },
    },
};

export default app;
